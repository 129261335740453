import React from "react";

import Body from "../../components/Body/Body";

const Home = props => {
  return (
    <>
      <Body />
    </>
  );
};

export default Home;
