const {
  "pmspa-api": { api, apiVersion }
} = require(`../../../config/${process.env.REACT_APP_API_CONFIG}`);

const getSingleTaskNotesCall = (taskId) => {
  return new Promise((resolve, reject) => {
    fetch(`${api}/${apiVersion}/notes?task=${taskId}`, {
      headers: {
        Authorization: document.cookie
          .split("; ")
          .find((cookie) => cookie.includes("Bearer"))
          ?.split("=")[1]
      },
      // Credentials: include for sending the cookie from the browser to the backend
      credentials: "include"
    })
      .then((res) => res.json())
      .then((json) => resolve(json))
      .catch((err) => reject(err));
  });
};

const getNotesCall = () => {
  return new Promise((resolve, reject) => {
    fetch(`${api}/${apiVersion}/notes`, {
      headers: {
        Authorization: document.cookie
          .split("; ")
          .find((cookie) => cookie.includes("Bearer"))
          ?.split("=")[1]
      },
      // Credentials: include for sending the cookie from the browser to the backend
      credentials: "include"
    })
      .then((res) => res.json())
      .then((json) => resolve(json))
      .catch((err) => reject(err));
  });
};

const submitNoteCall = (task, payload) => {
  return new Promise((resolve, reject) => {
    fetch(`${api}/${apiVersion}/notes/${task}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: document.cookie
          .split("; ")
          .find((cookie) => cookie.includes("Bearer"))
          ?.split("=")[1]
      },
      body: JSON.stringify(payload),
      // Credentials: include for sending the cookie from the browser to the backend
      credentials: "include"
    })
      .then((res) => res.json())
      .then((json) => resolve(json))
      .catch((err) => reject(err));
  });
};

const editNoteCall = (noteId, payload) => {
  return new Promise((resolve, reject) => {
    fetch(`${api}/${apiVersion}/notes/${noteId}?note=${payload}`, {
      method: "PATCH",
      headers: {
        Authorization: document.cookie
          .split("; ")
          .find((cookie) => cookie.includes("Bearer"))
          ?.split("=")[1]
      },
      // Credentials: include for sending the cookie from the browser to the backend
      credentials: "include"
    })
      .then((res) => res.json())
      .then((json) => resolve(json))
      .catch((err) => reject(err));
  });
};

const deleteNoteCall = (taskId, noteId) => {
  return new Promise((resolve, reject) => {
    fetch(`${api}/${apiVersion}/notes/${taskId}?noteId=${noteId}`, {
      method: "DELETE",
      headers: {
        Authorization: document.cookie
          .split("; ")
          .find((cookie) => cookie.includes("Bearer"))
          ?.split("=")[1]
      },
      // Credentials: include for sending the cookie from the browser to the backend
      credentials: "include"
    })
      .then((res) => res.json())
      .then((json) => resolve(json))
      .catch((err) => reject(err));
  });
};

export {
  getSingleTaskNotesCall,
  getNotesCall,
  submitNoteCall,
  editNoteCall,
  deleteNoteCall
};
