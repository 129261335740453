export const primary = "#ddd";
export const dark = "#333";
export const light = "#fff";
export const shadow = "0 1px 5px rgba(104, 104, 104, 0.8)";

// Example:
// export const black = "#222222";
// export const white = "#ffffff";

// export default {
//   background: white,
//   text: black
// };
